<script setup lang="ts">
const { t } = useT();
const { open } = useAppModals();
const prepareImgUrl = useImage();
const isDesktop = useMediaQuery("(min-width: 767px)");

const bannerBgM = `url(${prepareImgUrl("/nuxt-img/wheel-of-fortune/banner-bg-m.png", {
	format: "avif"
})})`;
const bannerBgD = `url(${prepareImgUrl("/nuxt-img/wheel-of-fortune/banner-bg-d.jpg", {
	format: "avif"
})})`;
</script>

<template>
	<div class="wheel-of-fortune-banner">
		<MHomeTitleFortuneWheelAnimation class="mobile" />
		<NuxtImg
			class="logo desktop"
			src="/nuxt-img/wheel-of-fortune/banner-logo-d.png"
			format="avif"
			alt="arrow"
			width="848"
			height="146"
		/>
		<div class="wheel-holder">
			<MWheelOfFortuneAnimated :size="isDesktop ? '462px' : '350px'" />
		</div>
		<div ref="refCoins" class="coins">
			<NuxtImg
				:src="`/nuxt-img/wheel-of-fortune/coins-${isDesktop ? '1' : '2'}.png`"
				format="avif"
				alt="coins"
				:width="isDesktop ? 379 : 440"
				:height="isDesktop ? 162 : 220"
			/>
		</div>
		<div v-for="(leave, index) in 4" :key="index" ref="refLeave" :class="`leaves leave-${index + 1}`">
			<NuxtImg :src="`/nuxt-img/wheel-of-fortune/leave-${index + 1}.png`" format="avif" alt="leave" />
		</div>
		<div class="content">
			<div class="title-block">
				<AText :size="{ full: 40, lg: 30, md: 24 }" as="h3" :modifiers="['uppercase']" class="title">
					{{ t("REWARDS EVERY DAY!") }}
				</AText>
				<AText :size="{ full: 28, lg: 22, md: 20 }" as="p" :modifiers="['bold']" class="subtitle">
					{{ t("Spin the Wheel twice a day") }}
				</AText>
			</div>
			<AButton class="button" variant="primary" size="xl" @click="open('LazyOModalSignup')">
				{{ t("Create an account") }}
			</AButton>
		</div>
	</div>
</template>

<style scoped lang="scss">
.wheel-of-fortune-banner {
	position: relative;
	z-index: 1;
	background: v-bind(bannerBgM) no-repeat center bottom / contain;
	height: 420px;
	border-radius: 20px;
	margin: 16px;
	@include media-breakpoint-down(md) {
		margin: 82px 16px 18px;
	}
	@include media-breakpoint-up(md) {
		background: v-bind(bannerBgD) no-repeat left bottom / cover;
		margin-top: 0;
		overflow: hidden;
	}
	.logo {
		display: block;
		margin: 11px auto;
		width: 678px;
		max-width: 100%;
		height: auto;
	}
	@include media-breakpoint-down(md) {
		:deep(.frame) {
			width: 100%;
			margin: -30px auto 0;
			.title {
				width: 90%;
			}
			.leaves {
				top: -52%;
				&.leave-1 {
					left: -8%;
				}
				&.leave-2 {
					right: -8%;
				}
				&.leave-3 {
					top: -46%;
					max-width: 55%;
				}
			}
		}
	}
	.wheel-holder {
		position: absolute;
		@include media-breakpoint-down(md) {
			width: 100%;
			height: 100%;
			overflow: hidden;
			bottom: 0;
			border-radius: 16px;
			.wheel-of-fortune-animated {
				margin: 200px 0 0;
				left: 50%;
				transform: translateX(-50%);
			}
		}
		@include media-breakpoint-up(md) {
			bottom: -180px;
			left: 30px;
		}
	}
	.coins {
		animation: coins 4s linear infinite;
		position: absolute;
		z-index: 5;
		filter: drop-shadow(3px 13px 9px rgba(var(--neutral-950-rgb), 0.5));
		@include media-breakpoint-down(md) {
			left: 50%;
			transform: translateX(-50%);
			bottom: 11vw;
			z-index: 7;
			img {
				width: 98vw;
				height: auto;
			}
		}
		@include media-breakpoint-up(md) {
			bottom: 117px;
			left: 33px;
		}
	}
	.leaves {
		pointer-events: none;
		position: absolute;
		z-index: 5;
		filter: drop-shadow(10px -5px 10px rgba(var(--neutral-950-rgb), 1));
		animation: leaves 4s linear infinite;
		&.leave-1 {
			animation-delay: 1s;
			animation-duration: 5s;
			left: calc(50% - 150px - 36px);
			bottom: -66px;
			z-index: 6;
			@include media-breakpoint-down(md) {
				img {
					width: 150px;
				}
			}
			@include media-breakpoint-up(md) {
				left: -33px;
				bottom: -115px;
			}
			@media screen and (max-width: 420px) {
				bottom: -36px;
			}
		}
		&.leave-2 {
			left: calc(50% - 170px - 68px);
			bottom: -64px;
			@include media-breakpoint-down(md) {
				img {
					width: 170px;
				}
			}
			@include media-breakpoint-up(md) {
				left: -81px;
				bottom: -71px;
			}
			@media screen and (max-width: 420px) {
				bottom: -20px;
			}
		}

		&.leave-3 {
			animation-delay: 2s;
			animation-duration: 5s;
			bottom: -55px;
			z-index: 6;
			@include media-breakpoint-down(md) {
				right: calc(50% - 150px - 30px);
				img {
					width: 150px;
				}
			}
			@include media-breakpoint-up(md) {
				left: 308px;
				bottom: -81px;
			}
			@media screen and (max-width: 420px) {
				bottom: -25px;
			}
		}
		&.leave-4 {
			animation-delay: 1s;
			animation-duration: 5s;
			bottom: -54px;
			@include media-breakpoint-down(md) {
				right: calc(50% - 160px - 80px);
				img {
					width: 160px;
				}
			}
			@include media-breakpoint-up(md) {
				left: 396px;
			}
			@media screen and (max-width: 420px) {
				bottom: -14px;
			}
		}
	}
	.content {
		position: relative;
		.title-block {
			line-height: 1.5;
			text-shadow:
				0 10px 15px rgba(var(--neutral-950-rgb), 0.1),
				0 4px 6px rgba(var(--neutral-950-rgb), 0.5);
		}
		@include media-breakpoint-down(md) {
			text-align: center;
			height: 84%;
		}
		@include media-breakpoint-up(md) {
			width: calc(50% - 80px);
			margin: 0 0 0 auto;
			.subtitle {
				margin: 8px 0 32px;
			}
		}
		@include media-breakpoint-up(lg) {
			width: calc(50% - 50px);
			margin: 46px 0 0 auto;
		}
	}

	.button {
		@include media-breakpoint-down(md) {
			width: 100%;
			max-width: 300px;
			position: absolute;
			bottom: 22px;
			left: 50%;
			transform: translateX(-50%);
			z-index: 6;
		}

		@include media-breakpoint-up(md) {
			width: 250px;
		}
	}
}
@keyframes coins {
	50% {
		scale: 0.95;
	}
}
@keyframes leaves {
	50% {
		transform: translateY(7px) rotate(2deg);
	}
}
</style>
